<template>
  <div>
    <div class="flex-middle" style="margin-bottom:1em">
      <Input
        style="width:13em;margin-right:1.2em;"
        v-model.trim="contentLevelStr"
        search
        enter-button="查等级"
        placeholder="如：1或1-10"
        @on-search="initPage()"
      />

      <Button type="primary" style="margin:0 0 0 auto" @click="addNewConfig">新增设置</Button>
    </div>
    <Modal v-model="showForm" :title="'创建精读推送配置'" footer-hide>
      <IntensivePushForm :editData="editItem" @close="onFormclose" v-if="showForm" />
    </Modal>

    <Table :columns="columns" :data="list" width="680">
      <template slot="no" slot-scope="{ index }">{{(pageNo-1)*pageSize+index+1}}</template>
      <template
        slot="lvl"
        slot-scope="{ index }"
      >{{list[index].minLevel}} - {{list[index].maxLevel}}</template>
      <template slot="info" slot-scope="{ index }">
        <div style="padding:.25em 0">
          <div
            style="padding:.125em 0"
            v-for="(step,i) in list[index].intensiveReadPushConfigList"
            :key="i"
          >{{i+1}}. {{step.categoryName}} - {{step.minute}}分钟</div>
        </div>
      </template>
      <template slot="isloop" slot-scope="{ index }">{{list[index].isCycle?'是':'否'}}</template>
      <template slot="opt" slot-scope="{ index }">
        <Button type="text" class="text-primary" @click="edit(list[index])">编辑</Button>
        <Poptip transfer confirm title="确定删除吗？" @on-ok="del(list[index])">
          <Button type="text" class="text-error">删除</Button>
        </Poptip>
      </template>
    </Table>
    <Page
      v-show="list.length"
      :total="totalCount"
      :page-size="pageSize"
      show-total
      show-sizer
      :current="pageNo"
      :page-size-opts="[pageSize]"
      @on-change="initPage"
    />
  </div>
</template>

<script>
import IntensivePushForm from "./IntensivePushForm";
import { getPushPage, deletePush } from "./readingModeSettings";

export default {
  components: { IntensivePushForm },
  data() {
    return {
      contentLevelStr: "",
      columns: [
        {
          title: "序号",
          slot: "no",
          width: 65
        },
        {
          title: "等级",
          slot: "lvl",
          width: 130
        },
        {
          title: "推送间隔",
          slot: "info",
          width: 200
        },
        {
          title: "是否循环",
          slot: "isloop",
          width: 95
        },
        {
          title: "操作",
          slot: "opt",
          width: 180,
          className: "intensive-push-opt-colum"
        }
      ],
      showForm: false,
      list: [{}],
      editItem: null,
      pageNo: 1,
      pageSize: 10,
      totalCount: 0
    };
  },
  methods: {
    initPage(pageNo = 1) {
      if (!/^(\d+)?-?(\d+)?$/.test(this.contentLevelStr)) {
        this.$Message.warning("请输入正确的等级区间！");
        return;
      }
      let [minLevel, maxLevel] = this.contentLevelStr.split("-");
      if (maxLevel === undefined) {
        maxLevel = minLevel;
      }
      getPushPage({
        pageNo,
        minLevel,
        maxLevel,
        pageSize: this.pageSize
      }).then(res => {
        this.totalCount = res.totalCount;
        this.pageNo = pageNo;
        this.list = res.rows;
      });
    },
    addNewConfig() {
      this.showForm = true;
      this.editItem = null;
    },
    del(item) {
      deletePush(item.id).then(res => {
        this.initPage();
      });
    },
    edit(item) {
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showForm = true;
    },
    onFormclose(e) {
      this.showForm = false;
      if (e) {
        this.initPage();
      }
    }
  },
  beforeMount() {
    this.initPage();
  }
};
</script>

<style scoped>
::v-deep th.intensive-push-opt-colum {
  padding-left: 1em;
}
</style>