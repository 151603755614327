import axios from '../api/newAxiosProxy';

// 以下配置，要么是对后台数据的拷贝，需注意注意与后台数据同步；要么是和需求有关，要随时和需求
export const readModes = {
  listen: "聆听",
  followReading: "跟读",
  aloudReading: "朗读",
  retell: "复述",
  seniorRetell: "高级复述",
  silentReading: "默读",
  recite: '背诵'
}

// 可以设置百分比模式的：
export const percentModes = ['followReading', 'listen', 'silentReading']

// 精读专用：
export const delicateSteps = {
  listen: '聆听',
  followReading: '跟读',
  // cycle: '循环'
}
// code转换成数字，注意与后台同步！
export const readModesOrder = [
  "",
  "listen",
  "followReading",
  "aloudReading",
  "retell",
  "seniorRetell",
  "silentReading",
  "recite",
  ""
];

const typeObj = {
  cn: 1,
  en: 3,
  cnIntensive: 2
}

export function getFirstTypes(typeName) {
  return axios({
    url: `/common/read/config/content/first/category/${typeObj[typeName]}`,
    method: 'get'
  }, true)

}
export function getModeSteps(moduleName) {
  return axios({
    url: `/common/read/config/content/read/module/category/${moduleName}`,
    method: 'GET'
  }, true).then(res => {
    return res.map(ele => {
      let key = Array.from(Object.keys(ele))[0];
      return {
        code: key,
        name: ele[key]
      };
    });
  });
}

export function getDelicatePartials(category) {
  return axios({
    url: `/common/read/config/content/read/module/intensive/${category}`,
    method: 'get'
  }, true)
}

export function submitConfig(data) {

  data.type = typeObj[data.typeName]
  console.log(data.type, data.typeName)
  if (data.intensiveReadConfigList) {
    data.intensiveReadConfigList.forEach((e, i) => {
      e.order = i + 1
    })
    // console.log(data.intensiveReadConfigList)
  }
  data.subject = data.contentType.substring(0, 2)
  data.materialType = 1
  if (data.id) {
    return axios({
      url: '/common/read/config/content/update',
      method: 'put',
      data
    }, true)
  }
  return axios({
    url: `/common/read/config/content/insert`,
    method: 'post',
    data
  }, true)

}

export function getConfigPage(params) {
  params.type = typeObj[params.typeName]
  return axios({
    url: `/common/read/config/content/page`,
    method: 'get',
    params
  }, true)
}

export function deleteConfig(id) {
  return axios({
    url: `/common/read/config/content/delete/${id}`,
    method: 'delete'
  }, true)
}
/**
 * @param {{contentType:string,category,subject,level}} params 
 */
export function getReadConfig(params) {
  params.subject = params.contentType.substring(0, 2).toUpperCase()
  return axios({
    url: `/common/read/config/content/find`,
    method: 'get',
    params
  })
}

export function submitPush(data) {
  // 推送
  if (!data.id) {
    return axios({
      url: '/common/read/config/content/intensive/push',
      method: 'post',
      data
    }, true)
  }
  return axios({
    url: '/common/read/config/content/intensive/push',
    method: 'put',
    data
  }, true)

}
export function getPushPage(params) {
  return axios({
    url: '/common/read/config/content/intensive/push/page',
    method: 'get',
    params
  }, true)
}
export function deletePush(id) {
  return axios({
    url: `/common/read/config/content/delete/push/${id}`,
    method: 'delete'
  }, true)
}