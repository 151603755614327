<template>
  <div style="padding:0 2em 0 0">
    <Form :model="formData" :label-width="100">
      <FormItem label="类型" v-if="baseInfoEditable">
        <FirstCodeSelect :typeName="'cnIntensive'" :withAll="false" v-model="formData.contentType" />
        <Category
          style="display:inline-block"
          :firstCode="formData.contentType"
          :hiddenThirdCategory="true"
          :secondCode.sync="formData.category"
          @update:secondCode="getPartials"
        />
      </FormItem>
      <FormItem label="等级区间" v-if="baseInfoEditable">
        <LevelRange :minLevel.sync="formData.minLevel" :maxLevel.sync="formData.maxLevel" />       
      </FormItem>

      <FormItem label="模式设置" v-if="partials">
        <div v-for="(conf,i) in configList" :key="i">
          <Select v-model="conf.chapterCode" style="width:100px">
            <Option v-for="(name,code) in partials" :key="code" :value="code">{{name}}</Option>
          </Select>
          <Icon
            class="text-error"
            type="md-remove-circle"
            @click="configList.splice(i,1)"
            style="font-size:1.15em;margin-left:.5em;cursor:pointer"
          />
          <div style="padding-left:2em" v-if="conf.chapterCode">
            <div v-for="(step,idx) in conf.intensiveReadModuleList" :key="idx">
              <Select v-model="step.playCode" style="width:100px">
                <Option v-for="(name,code) in steps" :key="code" :value="code">{{name}}</Option>
              </Select>
              <Input style="width:80px" type="number" v-model.number="step.count" placeholder="遍数" />遍
              <Icon
                class="text-error"
                type="md-remove-circle"
                @click="conf.intensiveReadModuleList.splice(idx,1)"
                style="font-size:1.15em;margin-left:.5em;cursor:pointer"
              />
            </div>
            <div
              class="text-success"
              style="display:inline-block;padding-left:1em;cursor:pointer"
              @click="addStep(conf)"
            >
              继续
              <Icon type="md-add-circle" style="font-size:1.15em;margin-left:.5em" />
            </div>
          </div>
        </div>
        <div
          class="text-success"
          style="display:inline-block;padding-left:1em;cursor:pointer"
          @click="addPartial()"
        >
          继续
          <Icon type="md-add-circle" style="font-size:1.15em;margin-left:.5em" />
        </div>
      </FormItem>

      <FormItem>
        <Poptip title="确定要放弃吗？" confirm @on-ok="cancel">
          <Button style="margin-right:2em">取消</Button>
        </Poptip>
        <Button @click="submit" type="primary">提交</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import {
  delicateSteps,
  submitConfig,
  getDelicatePartials
} from "./readingModeSettings";
import FirstCodeSelect from "./FirstCodeSelect";
export default {
  props: {
    editData: {},
    baseInfoEditable: {
      default: true
    },
    noSubmit: {
      default: false
    }
  },
  data() {
    return {
      partials: null,
      steps: delicateSteps,
      configList: [],
      formData: {
        contentType: "",
        category: "",
        minLevel: null,
        maxLevel: null,
        id: "",
        typeName:'cnIntensive'
      }
    };
  },
  components: { FirstCodeSelect },
  computed: {},
  methods: {
    validate() {
      if (this.baseInfoEditable) {
        if (!this.formData.contentType || !this.formData.category) {
          this.$Message.warning("请选择完类型和二级分类！");
          return false;
        }
        this.formData.minLevel?.[ this.formData.minLevel= Math.floor(this.formData.minLevel)];
        this.formData.maxLevel?.[ this.formData.maxLevel = Math.floor(this.formData.maxLevel)];
        if (          
          (this.formData.maxLevel??-1) < this.formData.minLevel ||
          (this.formData.minLevel??-1) < 0
        ) {
          this.$Message.warning("请填写正确的等级区间！");
          return false;
        }
      }
      if (!this.configList.length) {
        this.$Message.warning("请先设定模式！");
        return false;
      }
      for (let conf of this.configList) {
        if (!conf.chapterCode) {
          this.$Message.warning("存在未选择的章节");
          return false;
        }
        if (!conf.intensiveReadModuleList.length) {
          this.$Message.warning("存在没有添加步骤的章节");
          return false;
        }
        for (let step of conf.intensiveReadModuleList) {
          if (!step.playCode || !step.count || step.count <= 0) {
            this.$Message.warning("存在信息错误的步骤");
            return false;
          }
        }
      }
      return true;
    },
    cancel() {
      this.$emit("close", false);
    },
    getPartials() {
      // 切换了精读的类型
      if (!this.formData.category) {
        this.partials = null;
        return;
      }
      getDelicatePartials(this.formData.category).then(res => {
        this.partials = Object.assign({}, ...res);
        let conf =
          this.configList && this.configList.find(ele => !!ele.chapterCode);
        if (conf && !this.partials[conf.chapterCode]) {
          this.configList = [];
        }
      });
    },
    addPartial() {
      this.configList.push({
        chapter: "",
        chapterCode: "",
        intensiveReadModuleList: [
          {
            count: "",
            order: "",
            playCode: "",
            playName: ""
          }
        ]
      });
    },
    addStep(conf) {
      conf.intensiveReadModuleList.push({
        count: "",
        order: "",
        playCode: "",
        playName: ""
      });
    },
    factory() {
      if (!this.editData) {
        return;
      }
      this.formData.contentType = this.editData.contentType;
      this.formData.category = this.editData.category;
      this.formData.minLevel = this.editData.minLevel;
      this.formData.maxLevel = this.editData.maxLevel;
      this.formData.id = this.editData.id;
      this.configList = JSON.parse(
        JSON.stringify(this.editData.intensiveReadConfigList)
      );
      this.getPartials();
    },
    submit() {
      if (!this.validate()) {
        return;
      }
      this.configList.forEach(ele => {
        ele.chapter = this.partials[ele.chapterCode];
        ele.intensiveReadModuleList.forEach(step => {
          step.playName = this.steps[step.playCode];
        });
      });
      this.formData.intensiveReadConfigList = this.configList;
      if (this.noSubmit) {
        this.$emit("finish", this.formData);
      } else {
        submitConfig(this.formData).then(() => {
          this.$emit("close", true);
        });
      }
    }
  },
  beforeMount() {
    this.factory();
  }
};
</script>

<style scoped>
.flex-top {
  display: flex;
  align-items: flex-start;
}
</style>