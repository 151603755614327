<template>
  <div>
       <Tabs v-model="contentName" class="centertabs" style="width:19em;margin:auto">
         <TabPane
            :index="1"
            label="阅读模式设置"
            name="read"           
          />
          <TabPane
            :index="2"
            label="推送时间设置"
            name="push"           
          />
       </Tabs>
       <ConfigIntensive v-show="contentName==='read'" style="padding:1em 3em 0"/>
       <IntensivePushConfig v-show="contentName==='push'" style="padding:1em 3em 0"/>
  </div>
</template>

<script>
import ConfigIntensive from './ConfigIntensive'
import IntensivePushConfig from './IntensivePushConfig'
export default {
    components:{ConfigIntensive,IntensivePushConfig},
    props:{laun:{}},
    data(){
        return {
            contentName:'read' //read push 
        }
    }

}
</script>

<style>

</style>