<template>
  <div>
    <div class="flex-middle">
      <FirstCodeSelect
        :typeName="'cnIntensive'"
        v-model="condition.contentType"
        style="margin-right:1em"
        @input="initPage()"
      />
      <div style="width:10.5em">
        <Category
          v-show="condition.contentType!='all'"
          style="display:inline-block"
          :attachAllOpt="true"
          :firstCode="condition.contentType"
          :hiddenThirdCategory="true"
          :secondCode.sync="condition.category"
          @update:secondCode="initPage()"
        />
      </div>
      <Input
        style="width:13em;margin-right:1.2em;"
        v-model.trim="condition.contentLevelStr"
        search
        enter-button="查等级"
        placeholder="如：1或1-10"
        @on-search="initPage()"
      />
      <Button @click="resetCondition">重置</Button>
      <Button type="primary" style="margin:0 0 0 auto" @click="addNewConfig">新增设置</Button>
    </div>

    <div v-if="taskList&&taskList.length" style="padding-top:1em">
      <div v-for="(item,index) in taskList" :key="item.id" style="display:flex;padding:1em 0">
        <p style="width:2em">{{((pageNo - 1) * pageSize) + index + 1 }}.</p>
        <ConfigIntensiveItem style="flex:1" :item="item" />
        <div style="width:9em">
          <Button type="text" class="text-primary" @click="edit(item)">编辑</Button>
          <Poptip confirm title="确定删除吗？" @on-ok="del(item)">
            <Button type="text" class="text-error">删除</Button>
          </Poptip>
        </div>
      </div>
      <Page
        :total="totalCount"
        :page-size="pageSize"
        show-total
        show-sizer
        :current="pageNo"
        :page-size-opts="[pageSize]"
        @on-change="initPage"
      />
    </div>
    <div class="page-tip" v-else>{{pageTip}}</div>
    <Modal
      v-model="showForm"
      width="700"
      footer-hide
      :mask-closable="false"
      :title="editData?'编辑精读配置':'创建精读配置'"
    >
      <ConfigIntensiveForm :editData="editData" v-if="showForm" @close="onFormClose" />
    </Modal>
  </div>
</template>

<script>
import ConfigIntensiveForm from "./ConfigIntensiveForm";
import { getConfigPage, deleteConfig } from "./readingModeSettings";
import conditionCache from "../page-list/conditionCache";
import FirstCodeSelect from "./FirstCodeSelect";
import ConfigIntensiveItem from "./ConfigIntensiveItem";
export default {
  components: { ConfigIntensiveForm, FirstCodeSelect, ConfigIntensiveItem },
  data() {
    return {
      condition: conditionCache.getDelicateConfigCondition(),
      showForm: false,
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      taskList: [],
      pageTip: "没有符合条件的数据",
      editData: null
    };
  },
  methods: {
    addNewConfig() {
      this.editData = null;
      this.showForm = true;
    },
    edit(item) {
      this.editData = item;
      this.showForm = true;
    },

    resetCondition() {
      conditionCache.initWaitDealCondition("", false, 3);
    },
    onFormClose(bySubmit) {
      this.showForm = false;
      if (bySubmit) {
        this.initPage();
      }
    },
    initPage(pageNo = 1) {
      if (!/^(\d+)?-?(\d+)?$/.test(this.condition.contentLevelStr)) {
        this.$Message.warning("请输入正确的等级区间！");
        return;
      }
      let [minLevel, maxLevel] = this.condition.contentLevelStr.split("-");
      if (maxLevel === undefined) {
        maxLevel = minLevel;
      }
      let isContentAll = this.condition.contentType === "all";
      getConfigPage({
        typeName:'cnIntensive',
        pageNo,
        pageSize: this.pageSize,
        minLevel,
        maxLevel,
        
        contentType: isContentAll ? "" : this.condition.contentType,
        category: isContentAll ? "" : this.condition.category
      }).then(res => {
        this.totalCount = res.totalCount;
        this.taskList = res.rows;
        this.pageNo = pageNo;
      });
    },
    del(item) {
      deleteConfig(item.id).then(() => {
        this.initPage();
      });
    }
  },
  beforeMount() {
    this.initPage();
  }
};
</script>

<style>
</style>