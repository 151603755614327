<template>
  <div>
    <Form :label-width="100">
      <FormItem label="等级区间">
        <LevelRange :minLevel.sync="formData.minLevel" :maxLevel.sync="formData.maxLevel" />        
      </FormItem>
      <FormItem label="推送间隔">
        <div v-for="(step,i) in formData.intensiveReadPushConfigList" :key="i">
          <Category
            style="display:inline-block;width:9.5em"
            :firstCode="formData.contentType"
            :hiddenThirdCategory="true"
            :secondCode.sync="step.category"
          />
          <Input style="width:70px" placeholder="间隔" type="number" v-model.number="step.minute" />&nbsp;分钟
          <Icon
            class="text-error"
            type="md-remove-circle"
            @click="formData.intensiveReadPushConfigList.splice(i,1)"
            style="font-size:1.15em;margin-left:1em;cursor:pointer"
          />
        </div>
        <div class="text-success" style="padding-left:1em;cursor:pointer" @click="addStepToPush">
          继续
          <Icon type="md-add-circle" style="font-size:1.15em;margin-left:.5em" />
        </div>
      </FormItem>
      <FormItem label="是否循环">
        <i-switch v-model="formData.isCycle" true-color="rgb(25,190,107">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </FormItem>
      <FormItem>
        <Poptip title="确定要放弃吗？" confirm @on-ok="cancel">
          <Button style="margin-right:2em">取消</Button>
        </Poptip>
        <Button @click="submit" type="primary">提交</Button>
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { submitPush } from "./readingModeSettings";
export default {
  props: {
    editData: {}
  },
  data() {
    return {
      formData: {
        id: "",
        minLevel: null,
        maxLevel: null,
        intensiveReadPushConfigList: [],
        isCycle: false,
        contentType: "cnIntensive", // 固定值
        type: 2, // 固定值
        subject: "CN" // 固定值
      }
    };
  },
  methods: {
    validate() {
      this.formData.maxLevel?.[this.formData.maxLevel = Math.floor(this.formData.maxLevel)] ;
      this.formData.minLevel?.[this.formData.minLevel = Math.floor(this.formData.minLevel)] ;

      if ((this.formData.minLevel ??-1)< 0||this.formData.minLevel > (this.formData.maxLevel??-1)) {
        this.$Message.warning("请输入正确的等级区间");
        return false;
      }
      if (this.formData.intensiveReadPushConfigList.length <= 0) {
        this.$Message.warning("未设置推送时间间隔信息");
        return false;
      }
      for (let step of this.formData.intensiveReadPushConfigList) {
        step.minute = Math.round(step.minute);
        if (!step.category || step.minute <= 0) {
          this.$Message.warning("存在错误或空的推送间隔");
          return false;
        }
      }
      return true;
    },
    addStepToPush() {
      this.formData.intensiveReadPushConfigList.push({
        category: "",
        minute: 0,
        categoryName: ""
      });
    },
    cancel() {
      this.$emit("close", false);
    },
    submit() {
      if (!this.validate()) {
        return;
      }
      submitPush(this.formData).then(() => {
        this.$emit("close", this.formData);
      });
    }
  },
  beforeMount() {
    if (this.editData) {
      this.formData = Object.assign(this.formData, this.editData);
    }
  }
};
</script>

<style>
</style>