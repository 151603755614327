const normalConfigPageCondition = {
    contentType: 'all',
    category: '',
    contentLevelStr: "",
}
const delicateConfigPageCondition = {
    contentType: '',
    category: '',
    contentLevelStr: ""

}
export default {
    getNormalConfigCondition() {
        return normalConfigPageCondition
    },
    getDelicateConfigCondition() {
        return delicateConfigPageCondition
    },
    /**
     * 
     * @param {*} typeSelect 
     * @param {*} clearLastViewId 
     * @param {*} type 1 待处理列表， 2 普通配置的列表， 3 精读配置的列表， 9 所有
     */
    initWaitDealCondition(typeSelect = '', clearLastViewId = true, type = 1) {       
        if (2 === type || 9 === type) {
            normalConfigPageCondition.contentType = 'all'
            normalConfigPageCondition.category = ''
            normalConfigPageCondition.contentLevelStr = ''
        }

        if (3 === type || 9 === type) {
            delicateConfigPageCondition.contentType = ''
            delicateConfigPageCondition.category = ''
            delicateConfigPageCondition.contentLevelStr = ''
        }
    }
}