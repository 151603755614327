<template>
  <div>
    <div style="font-size:16px" v-if="baseInfoEditable">
      <span class="m-r-md">{{item.contentType | typePipe}}</span>
      <span class="m-r-md">{{item.categoryName}}</span>
      <span class="m-r-md">{{item.minLevel}} - {{item.maxLevel}} 级</span>
    </div>

    <div v-for="(mode,i) in item.intensiveReadConfigList" :key="i" style="padding-top:.5em">
      <b style="display:inline-block;width:10em">{{mode.chapter}}</b>
      <span
        v-for="(step,i) in mode.intensiveReadModuleList"
        :key="i"
      >{{step.playName}} {{step.count}}遍</span>
    </div>
  </div>
</template>

<script>
import { readModes } from "./readingModeSettings";
export default {
  props: {
    item: {},
    baseInfoEditable: {
      default: true
    },
  },
  data() {
    return {
      readModes: readModes
    };
  }
};
</script>

<style>
</style>