<template>
  <Select :value="value" @input="$emit('input',$event)" style="width:100px">
    <Option v-if="withAll" :value="'all'">全部</Option>
    <Option v-for="ctype in contentTypes" :key="ctype" :value="ctype">{{ ctype | typePipe }}</Option>
  </Select>
</template>

<script>
import {getFirstTypes} from './readingModeSettings'
export default {
  props: {
    value: {        
    },
    withAll:{
      default:true
    },
    typeName:{      
    }
  },
  data() {
    return {
      contentTypes: []
    };
  },
  methods: {},
  beforeMount(){
    getFirstTypes(this.typeName).then(res=>{
      this.contentTypes=res.map(ele=>Array.from(Object.keys(ele))[0])
      if(this.contentTypes.length===1){
        this.$emit('input',this.contentTypes[0])
      }
    })
  }
};
</script>

<style>
</style>